<template>
  <div id="app">
    <router-view />
    <div id="nav">
      <div class="row">
        <div class="col">
          <router-link to="/">Calculator</router-link> |
          <router-link to="/about">About</router-link> |
          <router-link to="/tutorial">Tutorial</router-link>
        </div>
        <div class="col">
          <router-link to="/">Option 1</router-link> |
          <router-link to="/">Option 2</router-link> |
          <router-link to="/">Option 3</router-link>
        </div>
        <div class="col">
          Art Passepartout
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

</style>

<script>
export default {
  name: 'Root',
  components: {},
  props: {
    apptitle: Text
  },
  methods: {}
}

</script>
